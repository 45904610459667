@tailwind base;
@tailwind components;
@tailwind utilities;

/* fonts  */
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700;900&display=swap");

body {
  font-family: "Cairo", sans-serif;
  @apply text-gray-500;
}

html {
  scroll-behavior: smooth;
}

/* Scroll styles  */

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6ec6c4;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3a9492;
}

/* Banner style  */
.text-bg-slide {
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0) 80%);
}

@media only screen and (max-width: 800px) {
  .text-bg-slide {
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 45%, rgba(255, 255, 255, 0.1) 100%);
  }
}

@media only screen and (max-width: 600px) {
  .text-bg-slide {
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 45%, rgba(255, 255, 255, 0.4) 100%);
  }
}

/* typography */
.e-title {
  @apply text-primary-500 font-bold uppercase text-2xl pb-4;
}

.e-pill-text {
  @apply bg-primary-50 text-primary-500 px-3 py-1 rounded-lg;
}

.e-transition {
  @apply transition duration-700 ease-in-out;
}
